@import "~antd/dist/antd.css";

.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categories-menu .ant-menu {
  background-color: #e8f3f8;
  width: 220px;
  margin-top: -5px;
  margin-left: -5px;
}

.categories-menu .ant-menu .ant-menu-item {
  margin: 0;
  height: auto;
  padding: 8px 16px;
  line-height: 1rem;
}
